// extracted by mini-css-extract-plugin
export var animatedBtn = "serviceSingleGallery-module--animated-btn--mmg0T";
export var bannerHeader = "serviceSingleGallery-module--banner-header--Oclwc";
export var btn = "serviceSingleGallery-module--btn--zm6Dx";
export var container = "serviceSingleGallery-module--container--D3lCq";
export var dark = "serviceSingleGallery-module--dark--HhpQB";
export var detailsTextPortable = "serviceSingleGallery-module--detailsTextPortable--t86GE";
export var deviceMockupsWrapper = "serviceSingleGallery-module--device-mockups-wrapper--V237Q";
export var gallerySwiper = "serviceSingleGallery-module--gallerySwiper--Ecqks";
export var headerFeatures = "serviceSingleGallery-module--header-features--w0mMw";
export var intro = "serviceSingleGallery-module--intro--WqriD";
export var lineIntro = "serviceSingleGallery-module--line-intro--a3hqs";
export var link = "serviceSingleGallery-module--link--EnPCt";
export var marqueText = "serviceSingleGallery-module--marque-text--NUlPM";
export var menuBackground = "serviceSingleGallery-module--menu-background--8jm78";
export var menuLink = "serviceSingleGallery-module--menu-link--Q9sk3";
export var overlay = "serviceSingleGallery-module--overlay--Qv5gJ";
export var pageBtn = "serviceSingleGallery-module--page-btn--mrFzo";
export var question = "serviceSingleGallery-module--question--v2ihb";
export var swiper = "serviceSingleGallery-module--swiper--Gs3Z1";
export var swiperImage = "serviceSingleGallery-module--swiperImage--WBUiy";
export var swiperSlide = "serviceSingleGallery-module--swiperSlide--JDZ23";
export var swiperSlideItem = "serviceSingleGallery-module--swiperSlideItem--9xbk-";
export var testimonial = "serviceSingleGallery-module--testimonial--HiJxe";
export var textColor = "serviceSingleGallery-module--text-color--AMWxi";
export var textUppercase = "serviceSingleGallery-module--text-uppercase--C2J6g";