// extracted by mini-css-extract-plugin
export var animatedBtn = "FAQList-module--animated-btn--OtRJ1";
export var answer = "FAQList-module--answer--iOHVN";
export var answerWrapper = "FAQList-module--answerWrapper--fGAeE";
export var bannerHeader = "FAQList-module--banner-header--rM47h";
export var btn = "FAQList-module--btn--oYmq8";
export var container = "FAQList-module--container--zjVgN";
export var dark = "FAQList-module--dark--cIDuo";
export var detailsTextPortable = "FAQList-module--detailsTextPortable--Pef38";
export var deviceMockupsWrapper = "FAQList-module--device-mockups-wrapper--oMMeY";
export var faqItem = "FAQList-module--faqItem--LCRFC";
export var faqPage = "FAQList-module--faqPage--GH1el";
export var faqSection = "FAQList-module--faqSection--zJDQX";
export var faqWrapper = "FAQList-module--faqWrapper--QN5KM";
export var headerFeatures = "FAQList-module--header-features--kxCwc";
export var intro = "FAQList-module--intro--DaUGe";
export var lineIntro = "FAQList-module--line-intro--EMQF4";
export var link = "FAQList-module--link--UumwY";
export var marqueText = "FAQList-module--marque-text--5ecUf";
export var menuBackground = "FAQList-module--menu-background--UWV3c";
export var menuLink = "FAQList-module--menu-link--3edNS";
export var overlay = "FAQList-module--overlay--X7xBd";
export var pageBtn = "FAQList-module--page-btn--B0OD9";
export var question = "FAQList-module--question--rqI6e";
export var testimonial = "FAQList-module--testimonial--Bq4MS";
export var textColor = "FAQList-module--text-color--QwUUQ";
export var textUppercase = "FAQList-module--text-uppercase--TA6Qg";