// extracted by mini-css-extract-plugin
export var animatedBtn = "serviceSingleMarque-module--animated-btn--tTIDh";
export var bannerHeader = "serviceSingleMarque-module--banner-header---TshG";
export var btn = "serviceSingleMarque-module--btn--9ZuJw";
export var container = "serviceSingleMarque-module--container--R7Rg+";
export var dark = "serviceSingleMarque-module--dark--wPWFq";
export var detailsTextPortable = "serviceSingleMarque-module--detailsTextPortable---PlA0";
export var deviceMockupsWrapper = "serviceSingleMarque-module--device-mockups-wrapper--AvsTm";
export var headerFeatures = "serviceSingleMarque-module--header-features--PgGcZ";
export var intro = "serviceSingleMarque-module--intro--6As1b";
export var lineIntro = "serviceSingleMarque-module--line-intro--JgZ7u";
export var link = "serviceSingleMarque-module--link--z4blV";
export var marqueText = "serviceSingleMarque-module--marque-text--4SreM";
export var menuBackground = "serviceSingleMarque-module--menu-background--nJ7dC";
export var menuLink = "serviceSingleMarque-module--menu-link--Fx3QK";
export var overlay = "serviceSingleMarque-module--overlay--ylTOA";
export var pageBtn = "serviceSingleMarque-module--page-btn--Vy-N4";
export var question = "serviceSingleMarque-module--question--77krb";
export var sectionServices = "serviceSingleMarque-module--sectionServices--7A2BE";
export var serviceMarqueeCard = "serviceSingleMarque-module--serviceMarqueeCard--m1g1f";
export var serviceSingleWrapper = "serviceSingleMarque-module--serviceSingleWrapper--2I90Z";
export var testimonial = "serviceSingleMarque-module--testimonial--RBTDH";
export var textColor = "serviceSingleMarque-module--text-color--ZPpzm";
export var textUppercase = "serviceSingleMarque-module--text-uppercase--kG+Vh";